import '@babel/polyfill';

class Store {
    constructor() {
        this.mapContainer = document.getElementById('shop-map');
        this.slider = document.getElementById('shop-slider');
        this.citiesLinks = document.querySelectorAll('[data-shop-city-id]');
        this.filterButton = document.getElementById('shop-filter');
        this.citiesContainer = document.getElementById('shop-cities');
        this.map = null;
        this.shops = null;
        this.currentCityId = '0';

        this.init();
    }

    async fetchData() {
        const response = await fetch('/store/json');
        return await response.json();
    }

    init() {
        this.fetchData()
            .then(data => {
                // Extract the cities and shops data from the JSON
                this.cities = data.cities;
                this.shops = data.shops;

                // Filter button shows hidden cities on mobile
                this.filterButton.addEventListener('click', () => {
                    this.toggleMobileFilter();
                });

                // Bind event listeners to the city selection links
                this.citiesLinks.forEach(link => {
                    link.addEventListener('click', event => {
                        // Prevent the default link behavior
                        event.preventDefault();

                        if(link.dataset.shopCityId === this.currentCityId) {
                            this.toggleMobileFilter();
                            return;
                        }

                        // Get the city ID from the link's data attribute
                        this.currentCityId = link.dataset.shopCityId;

                        // Remove the active class from all links
                        this.citiesLinks.forEach(l => l.classList.remove('active', 'show-on-mobile'));

                        // Add the active class to the clicked link
                        link.classList.add('active');

                        // Update the map and slides based on the selected city
                        this.updateMap(link.dataset.shopCityId);
                    });
                });

                // Initialize the map
                this.initMap();
            });
    }

    toggleMobileFilter(){
        this.citiesLinks.forEach(link => {
            link.classList.toggle('show-on-mobile');
        });
    }

    initMap() {
        this.map = new ymaps.Map(this.mapContainer, {
            center: [this.shops[0].latlng.split(', ')[0], this.shops[0].latlng.split(', ')[1]],
            zoom: 10,
            controls: ['fullscreenControl', 'zoomControl'],
        }, {
            searchControlProvider: 'yandex#search',
        });

        this.map.behaviors.disable('scrollZoom');

        this.updateMap('0');

        this.mapContainer.classList.remove('js-loading');
        this.citiesContainer.classList.remove('js-loading');
    }

    updateMap(cityId) {
        // Filter the shops array to only include shops in the selected city
        let shopsInCity = this.shops.filter(shop => shop.city_id === cityId);

        if (cityId === '0') {
            // Filter the shops array to only include shops in the selected city
            shopsInCity = this.shops;

            // Show all slides
            this.slider.querySelectorAll('[data-city-id]').forEach(slide => {
                slide.style.display = 'flex';
            });
        } else {
            // Hide the slides that are not relevant to the selected city
            this.slider.querySelectorAll('[data-city-id]').forEach(slide => {
                slide.style.display = slide.dataset.cityId === cityId ? 'flex' : 'none';
            });
        }

        // Remove all existing markers from the map
        this.map.geoObjects.removeAll();

        // Add markers for the new city's shops
        shopsInCity.forEach(shop => {
            const marker = new ymaps.Placemark([shop.latlng.split(', ')[0], shop.latlng.split(', ')[1]], {
                balloonContent: `
        <h3>${shop.name}</h3>
        <p>${shop.address}</p>
        <p>${shop.phone}</p>
        <a href="${shop.site}">${shop.site}</a>
      `
            });
            marker.options.set({
                iconColor: '#00cccc'
            });
            this.map.geoObjects.add(marker);
        });


        // Update the map center to the first shop's position
        this.map.setCenter([shopsInCity[0].latlng.split(', ')[0], shopsInCity[0].latlng.split(', ')[1]]);

        // Align the map zoom and center so that all selected markers are visible
        if (shopsInCity.length > 1) {
            const bounds = this.map.geoObjects.getBounds();
            this.map.setBounds(bounds, {
                checkZoomRange: true
            });
        } else {
            this.map.setZoom(14);
        }

    }
}

window.addEventListener('DOMContentLoaded', () => {
    if (typeof ymaps !== 'undefined') {
        ymaps.ready(init);
    }

    function init() {
        const store = new Store();
    }
})
