import Glide from "@glidejs/glide";

// <div className="glide" data-glide="0">
//     <div className="glide__track" data-glide-el="track">
//         <ul className="glide__slides">
//             <li className="glide__slide">
//                 123
//             </li>
//         </ul>
//         <div class="glide__bullets" data-glide-el="controls[nav]">
//             <button class="glide__bullet" data-glide-dir="=0"></button>
//             <button class="glide__bullet" data-glide-dir="=1"></button>
//             <button class="glide__bullet" data-glide-dir="=2"></button>
//         </div>
//     </div>
// </div>

document.addEventListener("DOMContentLoaded",function(){
    const glides = document.querySelectorAll('[data-glide]');

    const glade_config = [
        {
            type: 'carousel',
            perView: 1,
            autoplay: 2000,
            controls: {
                nav: {
                    perView: 1,
                }
            }
        },
        {
            type: 'carousel',
            perView: 3,
            autoplay: 2000,
            controls: {
                nav: {
                    perView: 3,
                }
            }
        }
    ];

    glides.forEach((slider) => {
        const glide = new Glide(slider, glade_config[slider.dataset.glide]);

        // Check if there are enough slides to show
        if (slider.querySelectorAll('.glide__slides > li').length > glide.settings.perView) {
            glide.mount();
        }
    });
});
