require('../scss/app.scss');

window.noZensmooth = true;

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/burger-menu.js');

require('./common/_glide');

require('./pages/_index');
require('./pages/_store');


// Зависимости
import "@fortawesome/fontawesome-free/js/all";

import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
