import LiveEvent from "../../components/live/live";

new LiveEvent('click', '[data-specification-bottom]', function (e) {
    e.preventDefault();
    let target = document.querySelector('[data-specification-block]');
    target.classList.toggle('show');
    this.classList.toggle('hide');
    target.scrollIntoView({
        behavior: 'smooth',
    });
});

document.addEventListener("DOMContentLoaded",function(){

    document.querySelectorAll('[data-tv-model]').forEach(link => {

        link.classList.remove('js-loading');

        link.addEventListener('click', event => {
            event.preventDefault();
            const tvModel = event.target.getAttribute('data-tv-model');
            if (tvModel === '100') {
                document.querySelectorAll('[data-tv-model="120"]').forEach(element => element.classList.remove('active'));
                document.querySelectorAll('[data-tv-model="100"]').forEach(element => element.classList.add('active'));
                document.querySelectorAll('.tv-model-120').forEach(element => element.classList.remove('active'));
                document.querySelectorAll('.tv-model-100').forEach(element => element.classList.add('active'));
            } else if (tvModel === '120') {
                document.querySelectorAll('[data-tv-model="100"]').forEach(element => element.classList.remove('active'));
                document.querySelectorAll('[data-tv-model="120"]').forEach(element => element.classList.add('active'));
                document.querySelectorAll('.tv-model-120').forEach(element => element.classList.add('active'));
                document.querySelectorAll('.tv-model-100').forEach(element => element.classList.remove('active'));
            }
        });
    });

});
